:root {
  --level-background-color: #fafafa;
  --level-color: #3d3d3d;
  --level-border-color: #e5e5e5;
  --level-content-color: #363636;
}

:root[data-theme='dark'] {
  --level-background-color: #18181a;
  --level-color: #d7d7db;
  --level-border-color: #4a4a4f;
  --level-content-color: #b1b1b3;
}

.level {
  display: flex;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  color: var(--level-color);
  background: var(--level-background-color);
  border: 1px solid var(--level-border-color);
  border-radius: 2px;
}

.level__item {
  flex-grow: 1;
  text-align: center;
}

.level__heading {
  display: block;
  font-size: 0.75rem;
  letter-spacing: 1px;
  margin-bottom: 5px;
  text-transform: uppercase;
  padding: 0;
}

.level__content {
  color: var(--level-content-color);
  font-weight: 600;
  padding: 0;
  margin: 0;
}
