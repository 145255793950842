:root {
  --content-width: 1200px;

  /* --element-ltr: #fb8c00; */
  /* --element-int: #1e88e5; */
  /* --element-nonerv: #43a047; */
  /* --element-gray: #bdbdbd; */
  /* --other-entity: #bcaaa4; */
  /* --tooltip-color: #01579b; */
  /* --header-row-height: 3rem; */
  /* --link-color: #3777b0; */
  /* --link-color-hover: #255076; */

  --background-color: #fcfcfc;
  --text-color: #212529;

  /* --header-background-color: #fafafa; */
  /* --header-border-color: #e5e5e5; */
  /* --header-item-color: #555; */
  /* --header-item-color-hover: #000; */
  /* --header-item-color-hover-botom: #c4c4c4; */
  /* --header-item-active-color: #000; */
  /* --header-item-active-border: #498aeb; */
  /* --header-home-color: #000; */
  /* --header-home-hover-color: #084e8c; */
}

:root[data-theme='dark'] {
  /* --element-ltr: #cc7200; */
  /* --element-int: #1362a7; */
  /* --element-nonerv: #00701a; */
  /* --element-gray: #757575; */
  /* --other-entity: #bcaaa4; */
  /* --tooltip-color: #79b9ec; */

  --background-color: #232327;
  /* --header-background-color: #0c0c0d; */

  --text-color: #d7d7db;

  /* --link-color: #75bfff; */

  /* --link-color-hover: #0a84ff; */

  /* --header-item-color: #b2b2b2; */
  /* --header-item-color-hover: #fff; */

  /* --header-item-active-color: #fff; */
  /* --header-home-color: #fff; */
  /* --header-home-hover-color: #75bfff; */
  /* --header-border-color: #4a4a4f; */
}

body {
  background: var(--background-color);
}

.header {
  max-width: var(--content-width);
  margin: 0 auto;
}

/* Must increase value of selector because of ".bp4-dark a" */
.header .header__logo {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  color: inherit;
}

.header__logo:hover {
  text-decoration: none;
}

.header__logo img {
  height: 30px;
  margin-right: 10px;
}

/* .header__search {
  background: var(--gray1);
} */

.main-wrapper {
  padding: 20px 0;
}

.main {
  max-width: var(--content-width);
  margin: 0 auto;
}

.width-100 {
  width: 100%;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-left-auto {
  margin-left: auto;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.white-space-pre {
  white-space: pre;
}

.flex-columns {
  display: flex;
}

.flex-columns > div {
  flex: 1;
}

.flex-columns--20 > div {
  padding: 20px;
}

.flex-columns--20 > div:first-child {
  padding-left: 0;
}

.flex-columns--20 > div:last-child {
  padding-right: 0;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.flex-wrap {
  flex-wrap: wrap;
}

.font-size-0 {
  font-size: 0;
}

.no-arrows input::-webkit-outer-spin-button,
.no-arrows input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-arrows input[type='number'] {
  -moz-appearance: textfield;
}

.no-wrap {
  white-space: nowrap;
}

.word-break-all {
  word-break: break-all;
}

.dl1 {
}

.dl1 dt {
  font-family: monospace;
  /* font-weight: bold; */
  margin-top: 10px;
  text-transform: uppercase;
  /* letter-spacing: 2px; */
  padding: 5px 0;
}

/* .dl1 dt::first-letter {
  font-size: 1.2em;
} */

.dl1 dd {
  margin-left: 10px;
}

/* .inline-note {
  font-size: 0.8rem;
  margin-left: 5px;
} */

.institutes {
  display: flex;
  justify-content: space-evenly;
}

.text-align-center {
  text-align: center;
}
