$pt-grid-size: 12px;
// $pt-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$pt-line-height: 1.6;

// $black: #000;

// $dark-gray1: darken(#18181a, 5%);
// $dark-gray2: #18181a;
// $dark-gray3: lighten(#18181a, 2%);
// $dark-gray4: lighten(#18181a, 4%);
// $dark-gray5: lighten(#18181a, 6%);

$navbar-background-color: #fafafa;
$dark-navbar-background-color: #0c0c0d;

@import '~normalize.css';
@import '~@blueprintjs/core/src/blueprint.scss';
@import '~@blueprintjs/select/src/blueprint-select.scss';
@import '~@blueprintjs/popover2/src/blueprint-popover2.scss';

:root {
  --black: #{$black};

  --dark-gray1: #{$dark-gray1};
  --dark-gray2: #{$dark-gray2};
  --dark-gray3: #{$dark-gray3};
  --dark-gray4: #{$dark-gray4};
  --dark-gray5: #{$dark-gray5};

  --gray1: #{$gray1};
  --gray2: #{$gray2};
  --gray3: #{$gray3};
  --gray4: #{$gray4};
  --gray5: #{$gray5};

  --light-gray1: #{$light-gray1};
  --light-gray2: #{$light-gray2};
  --light-gray3: #{$light-gray3};
  --light-gray4: #{$light-gray4};
  --light-gray5: #{$light-gray5};

  --white: #{$white};

  --blue1: #{$blue1};
  --blue2: #{$blue2};
  --blue3: #{$blue3};
  --blue4: #{$blue4};
  --blue5: #{$blue5};

  --green1: #{$green1};
  --green2: #{$green2};
  --green3: #{$green3};
  --green4: #{$green4};
  --green5: #{$green5};

  --orange1: #{$orange1};
  --orange2: #{$orange2};
  --orange3: #{$orange3};
  --orange4: #{$orange4};
  --orange5: #{$orange5};

  --red1: #{$red1};
  --red2: #{$red2};
  --red3: #{$red3};
  --red4: #{$red4};
  --red5: #{$red5};

  --vermilion1: #{$vermilion1};
  --vermilion2: #{$vermilion2};
  --vermilion3: #{$vermilion3};
  --vermilion4: #{$vermilion4};
  --vermilion5: #{$vermilion5};

  --rose1: #{$rose1};
  --rose2: #{$rose2};
  --rose3: #{$rose3};
  --rose4: #{$rose4};
  --rose5: #{$rose5};

  --violet1: #{$violet1};
  --violet2: #{$violet2};
  --violet3: #{$violet3};
  --violet4: #{$violet4};
  --violet5: #{$violet5};

  --indigo1: #{$indigo1};
  --indigo2: #{$indigo2};
  --indigo3: #{$indigo3};
  --indigo4: #{$indigo4};
  --indigo5: #{$indigo5};

  --cerulean1: #{$cerulean1};
  --cerulean2: #{$cerulean2};
  --cerulean3: #{$cerulean3};
  --cerulean4: #{$cerulean4};
  --cerulean5: #{$cerulean5};

  --turquoise1: #{$turquoise1};
  --turquoise2: #{$turquoise2};
  --turquoise3: #{$turquoise3};
  --turquoise4: #{$turquoise4};
  --turquoise5: #{$turquoise5};

  --forest1: #{$forest1};
  --forest2: #{$forest2};
  --forest3: #{$forest3};
  --forest4: #{$forest4};
  --forest5: #{$forest5};

  --lime1: #{$lime1};
  --lime2: #{$lime2};
  --lime3: #{$lime3};
  --lime4: #{$lime4};
  --lime5: #{$lime5};

  --gold1: #{$gold1};
  --gold2: #{$gold2};
  --gold3: #{$gold3};
  --gold4: #{$gold4};
  --gold5: #{$gold5};

  --sepia1: #{$sepia1};
  --sepia2: #{$sepia2};
  --sepia3: #{$sepia3};
  --sepia4: #{$sepia4};
  --sepia5: #{$sepia5};
}

.bp4-heading {
  font-weight: 400;
}

// Because ervd is compiling blueprint scss itself
// There is a problem with scss function "svg-icon"
// Paths are incorrect
.bp4-control.bp4-checkbox input:checked ~ .bp4-control-indicator::before {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E");
}

.bp4-omnibar-overlay .bp4-overlay-backdrop {
  background-color: #00000090;
}

body.bp4-overlay-open {
  overflow: inherit;
}

.bp4-navbar {
  height: auto;
  min-height: $pt-navbar-height;
}
