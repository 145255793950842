:root,
:root[data-theme='light'] {
  --cigar-M: var(--dark-gray2);
  --cigar-I: var(--green2);
  --cigar-D: var(--red2);
}

:root[data-theme='dark'] {
  --cigar-M: var(--gray4);
  --cigar-I: var(--green4);
  --cigar-D: var(--red4);
}

.cigar-string__item {
  margin-right: 3px;
}

.cigar-string__item:last-child {
  margin-right: 0;
}
