.email {
  /* vertical-align: middle; */
  display: flex;
  align-items: center;
}

.email__address {
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
}

.email__envelope {
  margin: 0 5px;
}

.email__at {
  /* width: 50px; */
  display: inline-block;
  padding: 0 4px;
}

.email__at::before {
  font-family: monospace;
  content: '@';
}
