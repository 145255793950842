.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination__page {
  margin: 0 5px;
}

.pagination > * {
  padding: 10px;
  /* border: 1px solid #0051d4; */
}
