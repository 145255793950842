:root {
  --entity-bar-border-color: #ccc;
}

:root[data-theme='dark'] {
  --entity-bar-border-color: #4a4a4f;
}

:root {
  --element-ltr: #fb8c00;
  --element-int: #1e88e5;
  --element-nonerv: #43a047;
  --element-gray: #bdbdbd;
  --other-entity: #bcaaa4;

  --entity-button-border: #bfccd6;
  --entity-button-background: #fafafa;
  --entity-button-border-hover: #b1bec7;
  --entity-button-background-hover: #f0f0f0;
}

:root[data-theme='dark'] {
  --element-ltr: #cc7200;
  --element-int: #1362a7;
  --element-nonerv: #00701a;
  --element-gray: #757575;
  --other-entity: #bcaaa4;

  --entity-button-border: #353535;
  --entity-button-background: #202020;
  --entity-button-border-hover: #000;
  --entity-button-background-hover: #131313;
}

.entity-bar {
  height: 20px;
  border: 1px solid var(--entity-bar-border-color);
  position: relative;
}

.entity-bar__item {
  height: 100%;
}

.entity-bar--inline {
  display: inline-block;
  height: 15px;
  vertical-align: middle;
}

.entity-bar--on-link {
  width: 50px;
  margin-right: 10px;
}

.entity-bar--on-link:not(:first-child) {
  margin-left: 10px;
}

.entity-bar__item {
  position: absolute;
  background: var(--element-nonerv);
}

.entity-bar__item--ltr {
  background: var(--element-ltr);
}

.entity-bar__item--int {
  background: var(--element-int);
}

.entity-link {
  display: inline-block;
}

.entity-button {
  display: inline-flex;
  align-items: center;
  padding: 5px;
  border: 1px solid var(--entity-button-border);
  background: var(--entity-button-background);
  margin: 0 10px 10px 0;
  border-radius: 3px;
}

.entity-button:hover {
  border: 1px solid var(--entity-button-border-hover);
  background: var(--entity-button-background-hover);
  text-decoration: none;
}
