:root {
  --card-header: #f6f6f6;
  --card-border: #e5e5e5;
}

:root[data-theme='dark'] {
  --card-header: #18181a;
  --card-border: #4a4a4f;
}

.card {
  border: 1px solid var(--card-border);
  border-radius: 2px;
}

.card--boxed {
  margin: 0 10px 10px 0;
}

.card__header {
  padding: 0.5rem 1.25rem;
  background: var(--card-header);
  border-radius: 3px 3px 0 0;
  border-bottom: 1px solid var(--card-border);
}

.card__body {
  padding: 1rem;
}
