:root,
:root[data-theme='light'] {
  --box-background-color: #fafafa;
  --box-border-color: #e5e5e5;
}

:root[data-theme='dark'] {
  --box-background-color: #222225;
  --box-border-color: #4a4a4f;
}

.box {
  padding: 10px;
  margin: 20px 0;
  background: var(--box-background-color);
  border: 1px solid var(--box-border-color);
  border-radius: 2px;
}

.box2 {
  margin: 10px 0;
  padding: 15px;
  border-left: 3px solid var(--box-border-color);
  background: var(--box-background-color);
  border-radius: 2px;
}
