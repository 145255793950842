:root {
  --browser-controls-background-color: #f5f5f5;
  --browser-controls-header-background-color: #eeeeee;
  --entity-chart-cytoband-stain-gpos100: #000000;
  --entity-chart-cytoband-stain-gpos: #000000;
  --entity-chart-cytoband-stain-gpos75: #828282;
  --entity-chart-cytoband-stain-gpos66: #a0a0a0;
  --entity-chart-cytoband-stain-gpos50: #c8c8c8;
  --entity-chart-cytoband-stain-gpos33: #d2d2d2;
  --entity-chart-cytoband-stain-gpos25: #c8c8c8;
  --entity-chart-cytoband-stain-gvar: #dcdcdc;
  --entity-chart-cytoband-stain-gneg: transparent;
  --entity-chart-cytoband-stain-acen: #728293;
  --entity-chart-cytoband-stain-stalk: #647fa4;
  --entity-chart-cytoband-stain-gneg: #eee;
  --entity-chart-cytoband-border: #bbb;
  --entity-chart-cytoband-range: #c62828;
  --browser-loader-background: #0000003d;
  --browser-loader-text: #fff;
  --browser-position-selection-border: #ccc;
  --browser-position-selection-invalid: var(--red1);
}

:root[data-theme='dark'] {
  --browser-controls-background-color: #18181a;
  --browser-controls-header-background-color: #18181a;
  --entity-chart-cytoband-stain-gpos100: #0d47a1;
  --entity-chart-cytoband-stain-gpos: #1565c0;
  --entity-chart-cytoband-stain-gpos75: #1976d2;
  --entity-chart-cytoband-stain-gpos66: #1e88e5;
  --entity-chart-cytoband-stain-gpos50: #2196f3;
  --entity-chart-cytoband-stain-gpos33: #42a5f5;
  --entity-chart-cytoband-stain-gpos25: #2196f3;
  --entity-chart-cytoband-stain-gvar: #64b5f6;
  --entity-chart-cytoband-stain-gneg: transparent;
  --entity-chart-cytoband-stain-acen: #2962ff;
  --entity-chart-cytoband-stain-stalk: #647fa4;
  --entity-chart-cytoband-stain-gneg: #777777;
  --entity-chart-cytoband-border: #616161;
  --entity-chart-cytoband-range: #c62828;
  --browser-loader-background: #ffffff20;
  --browser-loader-text: #000;
  --browser-position-selection-border: #4a4a4f;
  --browser-position-selection-invalid: var(--red3);
}

.browser {
  position: relative;
}

.browser-loader {
  position: absolute;
  background: var(--browser-loader-background);
  height: 100%;
  width: 100%;
  z-index: 10;
}

.browser-loader__content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.browser-loader__text {
  font-size: 2em;
  background: var(--browser-loader-text);
  padding: 0 10px;
}

.browser-controller {
  margin-top: 20px;
}

.browser-controls {
  background: var(--browser-controls-background-color);
  margin-top: 10px;
}

.browser-controls:last-child {
  margin-bottom: 10px;
}

.browser-controls__heading {
  background: var(--browser-controls-header-background-color);
  padding: 3px 10px;
}

.browser-controls__heading .bp4-control {
  margin-bottom: 0;
}

.browser-controls__content {
  padding: 5px 10px;
}

.browser-position-selection {
  display: flex;
  justify-content: center;
  padding: 2px 10px;
  font-size: 1.2em;
  margin-bottom: 30px;
}

.browser-position-selection form {
  border: 1px solid var(--browser-position-selection-border);
  border-radius: 5px;
  padding: 5px;
}

.browser-position-selection input {
  border: none;
  text-align: center;
  background: var(--background-color);
  color: var(--text-color);
  margin: 0 2px;
}

.browser-position-selection input:focus,
.browser-position-selection select:focus {
  outline: none;
}
